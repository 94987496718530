import React, { useEffect, useState } from "react";
import { Alert, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Modal, Paper, Snackbar, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import * as LayoutThunks from "./layout.thunks";
import { RootState } from "../../globals/redux/store/store.index";
import "./layout.index.less";
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Header from "../../globals/components/header/header.index";
import { Close, ExitToApp, Menu as AppMenu, ModelTraining, QuestionAnswerOutlined, Restore, Videocam, WarningAmberOutlined, Logout } from "@mui/icons-material";
import LogoutIcon from '@mui/icons-material/Logout';
import TranslateIcon from '@mui/icons-material/Translate';
import * as GlobalThunks from "./layout.thunks";
import * as ChatThunks from "../chatV2/chat.thunks";
import dayjs from "dayjs";
import HistoryIcon from '@mui/icons-material/History';
import LoginIcon from '@mui/icons-material/Login';
import * as ChatActions from "../chatV2/chat.actions";
import * as LayoutActions from "./layout.actions";
const Layout: React.FC = () => {

    // Selectors
    const globalAppState = useSelector(
        (state: RootState) => state.globalStateReducer,
    );

    const chatState = useSelector(
        (state: RootState) => state.chatV2Reducer,
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [isMobileView, setIsMobileView] = useState<boolean>(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 480); // Adjust the breakpoint as needed
        };

        // Initial check on component mount
        handleResize();

        // Event listener for window resize
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const shouldRenderIconButton = localStorage.getItem('freeUsageAllowed') && localStorage.getItem('freeUsageAllowed') == '1' ? false : true
    const [showRedirectionWarning, setShowRedirectionWarning] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState('English');

    const menuOpen = Boolean(anchorEl);
    const canAccessQnA = localStorage.getItem("canAccessQnA");
    const isBetaTester = localStorage.getItem("isBetaTester");
    // const canAccessVideo = localStorage.getItem("canAccessVideo");
    const handleHistoryButtonClick = () => {
        console.log("button clicked");
        dispatch(ChatActions.setToggleHistory(true))
        console.log(chatState.setToggleHistory)
        navigate("/chathistory")
    };

    const openDialog = () => {
        setIsDialogOpen(true);
    };

    const closeDialog = () => {
        setIsDialogOpen(false);
    };

    const confirmLogout = () => {
        handleLogout();
        closeDialog();
    };

    useEffect(() => {
        const refreshTokenExpiresOn = localStorage.getItem("refreshTokenExpiresOn");
        if (refreshTokenExpiresOn) {
            try {

                const dt = dayjs(refreshTokenExpiresOn);
                const now = dayjs(new Date());

                if (now > dt) {
                    // Refresh token is expired.
                    setShowRedirectionWarning(true);
                    setTimeout(() => {
                        handleLogout();
                    }, 2000);
                }

            } catch (error) {
                console.log("Nothing to see here!!")
            }
        }

        // eslint-disable-next-line
    }, []);

    if (canAccessQnA !== "yes" && window.location.pathname === "/q-and-a") {
        return <Navigate to="/chat" />
    }

    // if (window.location.pathname.startsWith("/v2") && (!isBetaTester || isBetaTester.toLowerCase() !== "yes")) {
    //     return <Navigate to="/chat" />
    // }
    if (window.location.pathname.startsWith("/old") && (!isBetaTester || isBetaTester.toLowerCase() !== "yes")) {
        return <Navigate to="/chat" />
    }

    const handleNotificationClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }
        dispatch(LayoutThunks.closeNotificationMessage());
    };

    const handleLogout = () => {
        const freeVal = localStorage.getItem('freeUsageAllowed')
        const machine_id = localStorage.getItem('machine_id')
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem('freeUsageAllowed', freeVal)
        localStorage.setItem('machine_id', machine_id)
        dispatch(GlobalThunks.resetState());
        window.location.href = "/login";
    }
    const handleLogin = () => {
        // localStorage.setItem('loggedIn', 'true');
        // setIsLoggedIn(true); // Update the login state
        navigate("/login");
    };


    const handleMenuButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const renderHeaderRightComponentOptions = () => {
        let options: any[] = [];

        if (location.pathname === "/chat") {
            // if (canAccessQnA.toLowerCase() === "yes") {
            if (canAccessQnA === "yes") {
                options.push(
                    <MenuItem className="menu-item" key="q-and-a" onClick={() => {
                        handleMenuClose();
                        dispatch(GlobalThunks.resetState());
                        // navigate("/v2/q-and-a");
                        navigate("/q-and-a");
                    }}>
                        <ListItemIcon><ModelTraining /></ListItemIcon>
                        <ListItemText className="menu-item-text">Go To Q&A Training</ListItemText>
                    </MenuItem>
                )
            }
            // if (canAccessVideo.toLowerCase() === "yes") {
            // if (chatState.chatType === "video") {
            //     options.push(
            //         <MenuItem className="menu-item" key="video-chat" onClick={() => {
            //             handleMenuClose();
            //             setTimeout(() => {
            //                 dispatch(ChatThunks.setChatType("text"));
            //             }, 300);
            //         }}>
            //             <ListItemIcon><Close /></ListItemIcon>
            //             <ListItemText className="menu-item-text">Exit Video Mode</ListItemText>
            //         </MenuItem>
            //     )
            // }
            // else if (chatState.chatType === "text") {
            //     options.push(
            //         <MenuItem className="menu-item" key="video-chat" onClick={() => {
            //             handleMenuClose();
            //             setTimeout(() => {
            //                 dispatch(ChatThunks.setChatType("video"));
            //             }, 300);
            //         }}>
            //             <ListItemIcon><Videocam /></ListItemIcon>
            //             <ListItemText className="menu-item-text">Switch To Video Mode</ListItemText>
            //         </MenuItem>
            //     )
            // }

            if (chatState.messages.length > 0) {
                options.push(
                    <MenuItem className="menu-item" key="restart-converation" onClick={() => {
                        handleMenuClose();
                        setTimeout(() => {
                            dispatch(ChatThunks.restartConversation(true));
                        }, 300);
                    }}>
                        <ListItemIcon><Restore /></ListItemIcon>
                        <ListItemText className="menu-item-text">Restart Conversation</ListItemText>
                    </MenuItem>
                )
            }
            else {
                options.push(
                    <MenuItem
                        className="menu-item"
                        key="restart-converation"
                        disabled
                    >
                        <ListItemIcon><Restore /></ListItemIcon>
                        <ListItemText className="menu-item-text">Restart Conversation</ListItemText>
                    </MenuItem>
                );
            }
        }
        // else if (location.pathname === "/v2/q-and-a") {
        else if (location.pathname === "/q-and-a") {
            options.push(
                <MenuItem className="menu-item" key="root" onClick={() => {
                    handleMenuClose();
                    setTimeout(() => {
                        dispatch(GlobalThunks.resetState());
                        // navigate("/v2");
                        navigate("/chat");
                    }, 300);
                }}>
                    <ListItemIcon><QuestionAnswerOutlined /></ListItemIcon>
                    <ListItemText className="menu-item-text">Converse</ListItemText>
                </MenuItem>
            )
        }

        return (<MenuList>{options}</MenuList>);

    }

    const renderHeaderRightMenu = () => {
        // if (location.pathname === "/v2" || location.pathname === "/v2/q-and-a") 
        if (location.pathname === "/chat" || location.pathname === "/q-and-a") {
            return (<>
                <div className="menu"><IconButton className="icon-button" id="page-menu" title="Menu" aria-label="Menu" onClick={handleMenuButtonClick} >
                    <AppMenu />
                </IconButton></div>
                <Menu
                    className="header-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    elevation={0}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    {renderHeaderRightComponentOptions()}
                </Menu>
            </>)
        }
        else {

            // if (canAccessQnA === "yes") {
            //     if (location.pathname === "/chat") {
            //         return (
            //             <IconButton onClick={() => {
            //                 dispatch(GlobalThunks.resetState());
            //                 navigate("/q-and-a");
            //             }} title="Q And A" aria-label="Q And A">
            //                 <ModelTraining />
            //             </IconButton>
            //         );
            //     }
            //     else if (location.pathname === "/q-and-a") {
            //         return (
            //             <IconButton onClick={() => {
            //                 dispatch(GlobalThunks.resetState());
            //                 navigate("/chat");
            //             }} title="Converse" aria-label="Converse">
            //                 <QuestionAnswerOutlined />
            //             </IconButton>
            //         );
            //     }
            // }

            if (canAccessQnA === "yes") {
                if (location.pathname === "/old") {
                    return (
                        <IconButton onClick={() => {
                            dispatch(GlobalThunks.resetState());
                            navigate("/old/q-and-a");
                        }} title="Q And A" aria-label="Q And A">
                            <ModelTraining />
                        </IconButton>
                    );
                }
                else if (location.pathname === "/old/q-and-a") {
                    return (
                        <IconButton onClick={() => {
                            dispatch(GlobalThunks.resetState());
                            navigate("/old");
                        }} title="Converse" aria-label="Converse">
                            <QuestionAnswerOutlined />
                        </IconButton>
                    );
                }
            }

            return (<></>);
        }
    }

    const languages = [
        'English',           // English (USA)
        '中文',                     // Chinese
        'Deutsch',                 // German
        'हिन्दी',                  // Hindi
        'Français (France)',       // French (France)
        'Français (Canada)',       // French (Canada)
        '한국어',                   // Korean
        'Português (Brasil)',      // Portuguese (Brazil)
        'Português (Portugal)',    // Portuguese (Portugal)
        'Italiano',                // Italian
        'Español (España)',        // Spanish (Spain)
        'Español (México)',        // Spanish (Mexico)
        'Bahasa Indonesia',        // Indonesian
        'Nederlands',              // Dutch
        'Türkçe',                  // Turkish
        'Filipino',                // Filipino
        'Polski',                  // Polish
        'Svenska',                 // Swedish
        'Български',               // Bulgarian
        'Română',                  // Romanian
        'العربية (السعودية)',       // Arabic (Saudi Arabia)
        'العربية (الإمارات)',       // Arabic (UAE)
        'Čeština',                 // Czech
        'Ελληνικά',                // Greek
        'Suomi',                   // Finnish
        'Hrvatski',                // Croatian
        'Bahasa Melayu',           // Malay
        'Slovenčina',              // Slovak
        'Dansk',                   // Danish
        'தமிழ்',                   // Tamil
        'Українська',              // Ukrainian
        'Русский',                 // Russian
    ];

    const menuItemStyle = {
        selected: {
            backgroundColor: 'rgb(35, 107, 72)',
            color: 'white',
        },
        hover: {
            backgroundColor: '#f0f0f0',
        },
    };

    const handleLanguageMenuClick = (event) => {
        setLanguageMenuAnchorEl(event.currentTarget);
    };

    const handleLanguageMenuChange = () => {
        setLanguageMenuAnchorEl(null);
    };

    const handleLanguageChange = (language) => {
        dispatch(LayoutActions.setLanguage(language));
        setSelectedLanguage(language);
        handleLanguageMenuChange();
    };

    return (
        <div className="layout">
            <div className="icon-container">

                <Header rightComponent={
                    <>
                        {shouldRenderIconButton && isMobileView && (
                            <div className="history">
                                <IconButton className="icon-button" onClick={handleHistoryButtonClick}>
                                    <HistoryIcon />
                                </IconButton>
                            </div>
                        )}

                        {canAccessQnA === "yes" && (
                            <>
                            <IconButton
                                id="language-menu"
                                title="Select your preferred language"
                                aria-label="Menu"
                                onClick={handleLanguageMenuClick}
                            >
                                <TranslateIcon style={{ fontSize: "20px" }} /> {selectedLanguage}
                            </IconButton>
                             
                        
                            <Menu
                                anchorEl={languageMenuAnchorEl}
                                keepMounted
                                open={Boolean(languageMenuAnchorEl)}
                                onClose={handleLanguageMenuChange}
                                PaperProps={{
                                    style: {
                                        maxHeight: 200,
                                        overflow: "auto",
                                    },
                                }}
                            >
                                {languages.map((language) => (
                                    <MenuItem
                                        key={language}
                                        onClick={() => handleLanguageChange(language)}
                                        style={
                                            selectedLanguage === language
                                                ? menuItemStyle.selected
                                                : {}
                                        }
                                        onMouseOver={(e) => {
                                            if (selectedLanguage !== language) {
                                                e.currentTarget.style.backgroundColor = menuItemStyle.hover.backgroundColor;
                                            }
                                        }}
                                        onMouseOut={(e) => {
                                            if (selectedLanguage !== language) {
                                                e.currentTarget.style.backgroundColor = 'inherit';
                                            }
                                        }}
                                    >
                                        <ListItemText className="menu-item-text">{language}</ListItemText>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </>
                        )}


                        {renderHeaderRightMenu()}
                        {shouldRenderIconButton ? (
                            <IconButton onClick={openDialog} title="Logout" aria-label="Logout" className="icon-button">
                                <LogoutIcon />
                                <span className="icon-text">Log Out</span>
                            </IconButton>
                        ) : (
                            <IconButton onClick={handleLogin} title="Login" aria-label="Login" className="icon-button">
                                <LoginIcon />
                                <span className="icon-text">Log In</span>
                            </IconButton>
                        )}
                        <Dialog open={isDialogOpen} onClose={closeDialog}>
                            <div style={{ width: "350px", borderRadius: "50px" }}>
                                <DialogTitle style={{ fontSize: "20px", padding: "20px", color: "#595959", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <Logout style={{ fontSize: "57px" }} />
                                </DialogTitle>
                                <DialogContent style={{ fontSize: "17.5px", padding: "10px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    Are you sure you want to log out?
                                </DialogContent>
                                <DialogActions style={{ display: "flex", justifyContent: "center", padding: "25px" }}>
                                    <Button onClick={closeDialog} size="small"
                                        variant="outlined" style={{ color: "black", textTransform: "none", borderColor: "#c2c2c2", paddingLeft: "16px", paddingRight: "16px" }}>
                                        Cancel
                                    </Button>
                                    <Button onClick={confirmLogout} size="small"
                                        variant="contained" style={{ textTransform: "none", backgroundColor: "#236B48", color: "white", paddingLeft: "16px", paddingRight: "16px" }} autoFocus>
                                        Logout
                                    </Button>
                                </DialogActions>
                            </div>
                        </Dialog>
                    </>
                } />
                <Outlet />
                <Snackbar
                    open={globalAppState.showNotification}
                    autoHideDuration={5000}
                    onClose={handleNotificationClose}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                    <Alert
                        style={{ maxWidth: 480 }}
                        variant="filled"
                        severity={globalAppState.notificationSeverity}
                        onClose={handleNotificationClose}
                    >
                        {globalAppState.notificationMessage}
                    </Alert>
                </Snackbar>
                <Modal open={showRedirectionWarning} className="logout-modal">
                    <Paper className="content">
                        <WarningAmberOutlined className="icon" />
                        <Typography variant="h6" align="center">
                            Your session has expired. Redirecting you to login screen.
                        </Typography>
                    </Paper>
                </Modal>
            </div>
        </div>
    );
};

export default Layout;
